import BlogPostModel from "./BlogPost.model";
import {Link} from "react-router-dom";
import Logo from "../_helpers/Logo";
import MetaTags from "react-meta-tags";
import PrismicHelper from "../_helpers/Prismic";
import React from "react";

export default class Blog extends React.Component {

    constructor(props) {
        super(props);
        this.prismic = new PrismicHelper();
        this.state = {blog_posts: []};
    }

    componentDidMount() {
        this.prismic.getBlogPosts().then(apiResponse => {
            const blogPosts = [];
            apiResponse.results.forEach(apiResponseItem => {
                blogPosts.push(new BlogPostModel(apiResponseItem));
            });
            this.setState({blog_posts: blogPosts});
        });
    }

    renderBlogPosts() {
        return this.state.blog_posts.map(item => {
            return (
                <div key={item.uid} className="col-md-6 pb-9">
                    <div className="single-post bg-white rounded-8 border-color-2 light-mode-texts">
                        <div className="post-img">
                            <img src={item.image} alt="" className="rounded-top-10 w-100"/>
                        </div>
                        <div className="post-content pl-9 pt-8 pr-7 pb-10">
                            <h5 className="gr-text-12 mb-7 text-uppercase">{item.getFormattedDate()}</h5>
                            <h3 className="gr-text-7 font-weight-bold mb-5">{item.title}</h3>
                            <p className="gr-text-11 mb-8">{item.description}</p>
                            <Link to={"/blog/" + item.uid} className="btn-link font-weight-bold gr-text-12 text-uppercase">
                                Leer Post
                            </Link>
                        </div>
                    </div>
                </div>
            )
        });
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Blog - LemonPlot.com</title>
                    <meta id="meta-description" name="description" content="Blog de LemonPlot."/>
                </MetaTags>
                <div className="inner-banner pt-25 pt-lg-31 pb-lg-11 bg-default-2">
                    <div className="container">
                        <div className="row justify-content-center">
                            <Logo/>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-9">
                                <div className="px-md-12 text-center mb-11 mb-lg-13">
                                    <h2 className="title gr-text-2 mb-7">Blog</h2>
                                    <p className="gr-text-8 mb-0">Aprende de finanzas personales con LemonPlot</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-13 pb-lg-24 bg-default-2">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="row">
                                    {this.renderBlogPosts()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}