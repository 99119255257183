import BlogPostModel from "../blog/BlogPost.model";
import Logo from "../_helpers/Logo";
import MetaTags from "react-meta-tags";
import PrismicHelper from "../_helpers/Prismic";
import PropTypes from "prop-types";
import React from "react";
import {RichText} from "prismic-reactjs";

export default class BlogPost extends React.Component {

    constructor(props) {
        super(props);
        this.prismic = new PrismicHelper();
        this.state = {blog_post: new BlogPostModel(null)};
    }

    componentDidMount() {
        this.prismic.getBlogPost(this.props.match.params.id).then(apiResponse => {
            this.setState({blog_post: new BlogPostModel(apiResponse)});
        });
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{this.state.blog_post.title}</title>
                    <meta id="meta-description" name="description" content={this.state.blog_post.description}/>
                </MetaTags>
                <div className="pb-13 pb-lg-24 pt-lg-12 bg-default-2">
                    <div className="container">
                        <div className="row justify-content-center">
                            <Logo />
                            <div className="col-12 pb-9">
                                <div className="single-post bg-white rounded-8 border-color-2 light-mode-texts">
                                    <div className="post-img">
                                        <img src={this.state.blog_post.image} alt="" className="rounded-top-10 w-100"/>
                                    </div>
                                    <div className="px-md-15 py-15 text-center">
                                        <h2 className="title gr-text-2 mb-9">{this.state.blog_post.title}</h2>
                                        <p className="gr-text-8 mb-0">{this.state.blog_post.description}</p>
                                    </div>
                                    <div className="post-content pl-9 pt-8 pr-7 pb-10">
                                        <RichText render={this.state.blog_post.content}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }

}

BlogPost.propTypes = {
    match: PropTypes.object.isRequired,
}