import Prismic from '@prismicio/client';

export default class PrismicHelper {
    static host = 'https://lemonplot.cdn.prismic.io/api/v2';

    constructor() {
        self.client = Prismic.client(PrismicHelper.host)
    }

    getPage(uid) {
        return self.client.getByUID('page', uid);
    }

    getBlogPosts() {
        return self.client.query(
            Prismic.Predicates.at('document.type', 'blog_post'),
            { orderings : '[my.blog_post.date desc]' }
        )
    }

    getBlogPost(uid) {
        return self.client.getByUID('blog_post', uid);
    }

}
