import emailjs from 'emailjs-com';
import Logo from "../_helpers/Logo";
import MetaTags from "react-meta-tags";
import React from "react";

export default class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.sendContactForm = this.sendContactForm.bind(this);
        this.state = {
            name: "",
            email: "",
            message: "",
            helpMessage: null,
            helpIsError: false
        };
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    sendContactForm() {
        if (this.state.name.length < 3) {
            this.setState({helpMessage: "Introduce un nombre válido (mínimo 3 letras).", helpIsError: true})
            return;
        }

        if (!this.validateEmail(this.state.email)) {
            this.setState({helpMessage: "Introduce email válido.", helpIsError: true})
            return;
        }

        if (this.state.message.length < 20) {
            this.setState({helpMessage: "Introduce un mensaje válido (mínimo 20 letras).", helpIsError: true})
            return;
        }

        let templateParams = {"name": this.state.name, "email": this.state.email, "message": this.state.message};
        emailjs.send('default_service', 'lemonplot_contact-form', templateParams, 'user_7RLK4EnTyLcByxJZdSBN6')
            .then(() => {
                this.setState({name: "", email: "", message: "", helpMessage: "Tu email ha sido enviado. Te responderemos con la mayor brevedad posible.", helpIsError: false})
            }, () => {
                this.setState({helpMessage: "Un error desconocido ha sucedido. Ponte en contacto con nosotros directamente en info@lemonplot.com.", helpIsError: true})
            });
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Contacto - LemonPlot.com</title>
                    <meta id="meta-description" name="description" content="Página de contacto de LemonPlot."/>
                </MetaTags>
                <div className="inner-banner pt-29 pb-md-11 bg-default-2">
                    <div className="container">
                        <div className="row justify-content-center">
                            <Logo/>
                        </div>
                        <div className="row justify-content-center pt-5">
                            <div className="col-xl-8 col-lg-9">
                                <div className="px-md-15 text-center">
                                    <h2 className="title gr-text-2 mb-9">¡Contáctanos!</h2>
                                    <p className="gr-text-8 mb-13">Atenderemos tus dudas o problemas en un máximo de 48h.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" pb-5 pb-md-33 bg-default-2 ">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="login-form bg-white border-gray-3 px-8 pt-8 pb-9 px-sm-11 py-sm-11 shadow-1 rounded-10">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-7">
                                                <label htmlFor="name" className="gr-text-11 font-weight-bold text-blackish-blue">Nombre y Apellidos</label>
                                                <input className="form-control gr-text-11 border" type="text" id="name" placeholder="Introduce tu nombre y apellidos" value={this.state.name} onChange={(event) => this.setState({name: event.target.value})}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-7">
                                                <label htmlFor="email" className="gr-text-11 font-weight-bold text-blackish-blue">Email</label>
                                                <input className="form-control gr-text-11 border" type="text" id="email" placeholder="Introduce tu email" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group mb-7">
                                                <label htmlFor="message" className="gr-text-11 font-weight-bold text-blackish-blue">Mensaje</label>
                                                <textarea name="textblock" id="message" className="form-control gr-text-11 border-gray-3 gr-textarea-height" placeholder="Introduce tu mensaje" value={this.state.message} onChange={(event) => this.setState({message: event.target.value})}/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <span style={{color: this.state.helpIsError ? "red" : "green"}}>{this.state.helpMessage != null ? this.state.helpMessage : ""}</span>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group button-block mt-5 text-right">
                                                <button className="form-btn btn btn-primary" onClick={this.sendContactForm}>Enviar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}