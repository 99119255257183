import {Link, Route, BrowserRouter as Router, Switch} from "react-router-dom";
import Blog from "./blog/Blog";
import BlogPost from "./blog_post/BlogPost";
import Contact from "./contact/Contact";
import Home from "./home/Home";
import Page from "./page/Page";
import React from 'react';
import ReactDOM from 'react-dom';

export default class App extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route exact path='/blog' component={Blog}/>
                        <Route exact path='/blog/:id' component={BlogPost}/>
                        <Route exact path='/condiciones-de-uso' render={() => <Page page_id="terms"/>}/>
                        <Route exact path='/privacidad' render={() => <Page page_id="privacy"/>}/>
                        <Route exact path='/cookies' render={() => <Page page_id="cookies"/>}/>
                        <Route exact path='/contacto' component={Contact}/>
                    </Switch>
                    <div className="footer-section pt-0">
                        <div className="container">
                            <div className="copyright-area py-9">
                                <div className="row align-items-center">
                                    <div className="d-flex col-lg-6">
                                        <p className="copyright-text gr-text-11 mb-6 mb-lg-0 gr-text-color text-center text-lg-left">
                                            © 2021 LemonPlot
                                        </p>
                                        <span className="ml-5 mr-5">|</span>
                                        <div>
                                            <Link to="/blog" className="gr-text-11 gr-text-color gr-hover-text-red ml-3">Blog</Link>
                                            <Link to="/condiciones-de-uso" className="gr-text-11 gr-text-color gr-hover-text-red ml-3">Condiciones de Uso</Link>
                                            <Link to="/privacidad" className="gr-text-11 gr-text-color gr-hover-text-red ml-3">Privacidad</Link>
                                            <Link to="/cookies" className="gr-text-11 gr-text-color gr-hover-text-red ml-3">Cookies</Link>
                                            <Link to="/contacto" className="gr-text-11 gr-text-color gr-hover-text-red ml-3">Contacto</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-center text-lg-right">
                                        <ul className="social-icons list-unstyled mb-0">
                                            <li className="ml-7"><a href="https://www.facebook.com/lemonplot" className="gr-text-color gr-hover-text-red"><i className="fab fa-facebook"/></a></li>
                                            <li className="ml-7"><a href="https://www.instagram.com/lemon.plot/" className="gr-text-color gr-hover-text-red"><i className="fab fa-instagram"/></a></li>
                                            <li className="ml-7"><a href="https://twitter.com/lemonplot" className="gr-text-color gr-hover-text-red"><i className="fab fa-twitter"/></a></li>
                                            <li className="ml-7"><a href="https://www.youtube.com/c/lemonplot" className="gr-text-color gr-hover-text-red"><i className="fab fa-youtube"/></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Router>
            </React.Fragment>
        );
    }
}

ReactDOM.render(<App/>, document.getElementById('root'));
