import Logo from "../_helpers/Logo";
import MetaTags from "react-meta-tags";
import PrismicHelper from "../_helpers/Prismic";
import PropTypes from 'prop-types';
import React from "react";
import {RichText} from 'prismic-reactjs'

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.prismic = new PrismicHelper();
        this.state = {title: '', content: null};
    }

    componentDidMount() {
        this.prismic.getPage(this.props.page_id).then(apiResponse => {
            this.setState({title: apiResponse.data.page_title, content: apiResponse.data.page_content});
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.page_id !== prevProps.page_id) {
            this.componentDidMount()
        }
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{this.state.title}</title>
                    <meta id="meta-description" name="description" content=""/>
                </MetaTags>
                <div className="inner-banner bg-default-2 pt-29 pb-6">
                    <div className="container">
                        <div className="row justify-content-center">
                            <Logo/>
                        </div>
                        <div className="row justify-content-center pt-5">
                            <div className="col-xl-8 col-lg-9">
                                <div className="px-md-15 text-center">
                                    <h2 className="title gr-text-2 mb-9">{this.state.title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-block bg-default-2 pb-21">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="px-12">
                                    <RichText render={this.state.content}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

Page.propTypes = {
    page_id: PropTypes.string.isRequired,
}