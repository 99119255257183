import React from "react";

export default class HomeHeader extends React.Component {

    render() {
        return (
            <div className="site-wrapper overflow-hidden">
                <div className="hero-area-03 pt-29 pt-lg-32 pb-5 position-relative " style={{backgroundImage: "linear-gradient(225deg, rgb(4 123 254) 0%, rgb(4 123 254) 39%, rgb(5 67 136) 100%)"}}>
                    <div className="shape-1 gr-abs-tl" data-aos="fade-down-right" data-aos-duration="500" data-aos-once="true">
                        <img src="image/l6/png/l6-hero-shape1.png" alt=""/>
                    </div>
                    <div className="shape-2 gr-abs-tl" data-aos="fade-down-right" data-aos-duration="800" data-aos-delay="300" data-aos-once="true">
                        <img src="image/l6/png/l6-hero-shape2.png" alt=""/>
                    </div>
                    <div className="shape-3 gr-abs-tl" data-aos="fade-down-right" data-aos-duration="1100"
                         data-aos-delay="600" data-aos-once="true">
                        <img src="image/l6/png/l6-hero-shape3.png" alt=""/>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-lg-7 col-xl-6 align-items-center d-flex">
                                <div className="hero-content dark-mode-texts mb-15 mb-lg-30 text-center text-md-left ">
                                    <h1 className="gr-text-2 mb-3 text-white">LemonPlot</h1>
                                    <p className="gr-text-8 px-7 pl-md-0 pr-md-11 mb-0 gr-color-white-opacity-7">
                                        La app que te ayuda a contar tu dinero y verlo crecer.
                                    </p>
                                    <div className="download-block mt-8">
                                        <div className="download-btns d-flex flex-column flex-xs-row justify-content-center justify-content-md-start mt-7">
                                            <a href="https://apps.apple.com/es/app/lemonplot/id1563683106" target="_blank" rel="noreferrer">
                                                <img src="/img/download_app_store.svg" className="mr-xs-5 mb-5 mb-xs-0" alt="Descargar LemonPlot" width="200"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-lg-5 col-xl-6" data-aos="fade-up" data-aos-duration="2000" data-aos-once="true">
                                <div className="hero-img position-relative">
                                    <img src="/img/lemon_home_iphone.png" alt="" className="w-100"/>
                                    <div className="abs-img" data-aos="zoom-in" data-aos-delay="1600" data-aos-duration="800" data-aos-once="true">
                                        <img src="/img/l6-hero-pattern.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wave-shape">
                        <img src="img/l6-hero-wave.svg" alt="" className="w-100 light-shape default-shape"/>
                    </div>
                </div>
            </div>
        );
    }

}