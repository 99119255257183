export default class BlogPostModel {

    constructor(apiResponse) {
        if (apiResponse !== null) { this.load(apiResponse); }
    }

    load(apiResponse) {
        this.uid = apiResponse.uid;
        this.image = apiResponse.data.blog_post_image.url;
        this.title = apiResponse.data.blog_post_title;
        this.description = apiResponse.data.blog_post_description;
        this.date = new Date(apiResponse.data.blog_post_date);
        this.content = apiResponse.data.blog_post_content;
    }

    getFormattedDate() {
        const day = this.date.getDate() > 9 ? this.date.getDate().toString() : "0" + this.date.getDate().toString();
        const month = this.date.getMonth() > 8 ? (this.date.getMonth() + 1).toString() : "0" + (this.date.getMonth() + 1).toString();
        return day + "/" + month + "/" + this.date.getFullYear();
    }

}