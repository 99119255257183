import React from "react";

export default class Logo extends React.Component {
    render() {
        return (
            <a href="/" className="d-flex align-items-center pb-10">
                <img src="/img/logo.png" width="25" height="25"/>
                <h1 className="gr-text-6 text-black-50 mb-0 ml-3 font-weight-normal"><strong>Lemon</strong>Plot</h1>
            </a>
        )
    }
}