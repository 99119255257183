import React from "react";

export default class HomeVideo extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="how-section pb-13 pt-lg-13 pb-lg-25 mt-25 bg-default-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-7 col-md-9">
                                <div className="section-title text-center mb-11 mb-lg-17">
                                    <h2 className="title gr-text-3 mb-7">¿Cómo funciona?</h2>
                                    <p className="px-lg-8 gr-text-8">LemonPlot es fácil de utilizar, comprúebalo tú mism@ en el vídeo que hemos preparado para tí.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gradient-sections-wrapper bg-gradient-1 pb-15 pb-lg-25 mt-20 mt-lg-10 mt-xl-5" style={{background: 'linear-gradient(225deg, #ffd800 0%, #efd33c 100%)'}}>
                    <div className="shape">
                        <img src="img/l6-video-wave.svg" alt="" className="w-100 light-shape default-shape"/>
                        <img src="img/l6-video-wave-dark.svg" alt="" className="w-100 dark-shape"/>
                    </div>
                    <div className="video-area position-relative gr-z-index-1 mt-n29 mt-lg-n35">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="video-image-pattern-bottom ">
                                        <img className="w-100 rounded-20" src="img/video_screenshot.png" alt=""/>
                                        <a className="circle-xxl bg-white gr-abs-center gr-abs-hover-y gr-text-8" href="https://www.youtube.com/watch?v=883u9aJqDao" data-fancybox="">
                                            <i className="icon icon-triangle-right-17-2 text-red"></i>
                                        </a>
                                        <div className="gr-abs-bl-custom pattern" data-aos="fade-down-left" data-aos-duration="900" data-aos-once="true">
                                            <img src="/img/l6-hero-pattern.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-section pt-15 pt-lg-22 pb-15 pb-lg-25 d-none">
                        <div className="container">
                            <div className="row justify-content-center dark-mode-texts">
                                <div className="col-md-8 col-lg-7 col-xl-6">
                                    <div className="section-title text-center mb-11 mb-lg-15">
                                        <h2 className="title-sm gr-text-5 mb-0 text-white"><span className="strike-bottom green">1,749 remote teams</span> have shared their experience with our app!</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-10 col-lg-8 mb-7" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
                                    <div className="testimonial-card d-flex flex-column flex-md-row align-items-md-center border rounded-12 bg-white pt-9 pb-8 px-9 gr-hover-opacity-full">
                                        <div className="card-image rounded-circle mr-9 mb-7 mb-md-0">
                                            <img className="circle-xxl w-100" src="img/l6-testimonial-img1.jpg" alt=""/>
                                        </div>
                                        <div className="testimonial-content">
                                            <p className="review-text gr-text-7 text-blackish-blue mb-6">“OMG! I cannot believe that I have got a brand new landing page after getting Omega. It was super easy to edit and publish.”</p>
                                            <span className="name gr-text-9 text-blackish-blue gr-opacity-7 mb-0">Isaac Olson</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 col-lg-8 mb-7" data-aos="fade-right" data-aos-duration="800" data-aos-delay="200" data-aos-once="true">
                                    <div className="testimonial-card d-flex flex-column flex-md-row align-items-md-center border rounded-12 bg-white pt-9 pb-8 px-9 gr-opacity-7 gr-hover-opacity-full">
                                        <div className="card-image rounded-circle mr-9 mb-7 mb-md-0">
                                            <img className="circle-xxl w-100" src="img/l6-testimonial-img2.jpg" alt=""/>
                                        </div>
                                        <div className="testimonial-content">
                                            <p className="review-text gr-text-7 text-blackish-blue mb-6">“Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users who want success.”</p>
                                            <span className="name gr-text-9 text-blackish-blue gr-opacity-7 mb-0">Barry Young</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 col-lg-8 mb-7" data-aos="fade-right" data-aos-duration="800" data-aos-delay="400" data-aos-once="true">
                                    <div className="testimonial-card d-flex flex-column flex-md-row align-items-md-center border rounded-12 bg-white pt-9 pb-8 px-9 gr-opacity-5 gr-hover-opacity-full">
                                        <div className="card-image rounded-circle mr-9 mb-7 mb-md-0">
                                            <img className="circle-xxl w-100" src="img/l6-testimonial-img3.jpg" alt=""/>
                                        </div>
                                        <div className="testimonial-content">
                                            <p className="review-text gr-text-7 text-blackish-blue mb-6">“Must have book for all, who want to be Product Designer or Interaction Designer.”</p>
                                            <span className="name gr-text-9 text-blackish-blue gr-opacity-7 mb-0">Esther Allison</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}