import HomeExplanation from "./HomeExplanation";
import HomeHeader from "./HomeHeader";
import HomeVideo from "./HomeVideo";
import MetaTags from 'react-meta-tags';
import React from "react";

export default class Home extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>LemonPlot.com - La app que te ayuda a contar tu dinero y verlo crecer</title>
                    <meta id="meta-description" name="description" content="La app que te ayuda a contar tu dinero y verlo crecer"/>
                </MetaTags>
                <HomeHeader/>
                <HomeExplanation/>
                <HomeVideo/>
            </React.Fragment>
        )
    }
}