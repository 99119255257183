import React from "react";

export default class HomeExplanation extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="content-section pt-6 pt-lg-19 pb-5 bg-default-4">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6 col-lg-3" data-aos="flip-right" data-aos-duration="1000" data-aos-once="true">
                                <div className="content-img">
                                    <img src="/img/lemon_accounts_iphone.png" alt="" className="w-100"/>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 pl-xl-21">
                                <div className="section-title content-text mb-13">
                                    <h2 className="title gr-text-3 mb-6">Haz un seguimiento de tu patrimonio.</h2>
                                    <p className="gr-text-8">Agrupamos todas tus cuentas en un mismo lugar.</p>
                                </div>
                                <div className="content-widget">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-11 col-xl-11" data-aos="fade-left" data-aos-duration="500" data-aos-once="true">
                                            <div className="single-widget mb-9">
                                                <h3 className="w-title gr-text-7">Añade todas tus cuentas</h3>
                                                <p className="gr-text-9 mb-0">No importa que tengas una o cincuenta. Puedes dividir tu patrimonio en tantas cuentas como quieras.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-11 col-xl-11" data-aos="fade-left" data-aos-duration="1100" data-aos-once="true">
                                            <div className="single-widget mb-9">
                                                <h3 className="w-title gr-text-7">Algo más que euros</h3>
                                                <p className="gr-text-9 mb-0">Una vez creada una cuenta, puedes asignar tantas acciones, monedas o criptomonedas como quieras. Nosotros convertiremos su valor a tu moneda por defecto.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-section pt-6 pt-lg-19 pb-7 bg-default-4">
                    <div className="container">
                        <div className="row align-items-center justify-content-end">
                            <div className="col-6 col-lg-3 order-lg-2" data-aos="flip-right" data-aos-duration="2000" data-aos-once="true">
                                <div className="content-img text-right">
                                    <img src="/img/lemon_assets_iphone.png" alt="" className="w-100"/>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 pr-xl-13 order-lg-1">
                                <div className="section-title content-text mb-13">
                                    <h2 className="title gr-text-3 mb-6">Controla tus inversiones, una a una.</h2>
                                    <p className="gr-text-8">La rentabilidad de tus operaciones merecen tu atención.</p>
                                </div>
                                <div className="content-widget">
                                    <div className="row mb-n9">
                                        <div className="col-md-6 col-lg-9 col-xl-10" data-aos="fade-right" data-aos-duration="500" data-aos-once="true">
                                            <div className="single-widget mb-9">
                                                <h3 className="w-title gr-text-7">Control operación a operación</h3>
                                                <p className="gr-text-9 mb-0">Controla todas tus inversiones, una a una. Calculamos la rentabilidad de tus inversiones de manera independiente.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-9 col-xl-10" data-aos="fade-right" data-aos-duration="1100" data-aos-once="true">
                                            <div className="single-widget mb-9">
                                                <h3 className="w-title gr-text-7">Más de 6000 activos donde elegir</h3>
                                                <p className="gr-text-9 mb-0">Elige entre miles de activos. A día de hoy ya disponemos de miles de acciones, monedas y criptomonedas.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-section pt-6 pt-lg-19 pb-5 bg-default-4">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6 col-lg-3" data-aos="flip-right" data-aos-duration="1000" data-aos-once="true">
                                <div className="content-img">
                                    <img src="/img/lemon_incomes-expenses_iphone.png" alt="" className="w-100"/>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 pl-xl-21">
                                <div className="section-title content-text mb-13">
                                    <h2 className="title gr-text-3 mb-6">Estima tus gastos e ingresos.</h2>
                                    <p className="gr-text-8">Como una hoja de cálculo, pero con superpoderes.</p>
                                </div>
                                <div className="content-widget">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-11 col-xl-11" data-aos="fade-left" data-aos-duration="500" data-aos-once="true">
                                            <div className="single-widget mb-9">
                                                <h3 className="w-title gr-text-7">En euros, en dólares o en cualquier otra</h3>
                                                <p className="gr-text-9 mb-0">Si tienes gastos o ingresos en diferentes monedas, nosotros nos encargamos de convertirlos a tu moneda por defecto.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-11 col-xl-11" data-aos="fade-left" data-aos-duration="1100" data-aos-once="true">
                                            <div className="single-widget mb-9">
                                                <h3 className="w-title gr-text-7">Mensual o anual, nosotros nos encargamos</h3>
                                                <p className="gr-text-9 mb-0">Olvídate de multiplicaciones, tus gastos e ingresos se ajustan automáticamente según su periodicidad.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}